import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import GameList from './components/GameList';
import AdminPage from './components/AdminPage';
import SynthwaveBackground from './components/SynthwaveBackground';

import './i18n';
import './App.css';

function App() {
  const { t, i18n } = useTranslation();
  const USE_STATIC_DATA = process.env.CF_PAGES === '1' || process.env.REACT_APP_STATIC_RUN === '1';

  useEffect(() => {
    // 브라우저 언어 감지 및 적용
    const detectedLang = navigator.language.split('-')[0];
    if (['en', 'ko'].includes(detectedLang)) {
      i18next.changeLanguage(detectedLang);
    } else {
      i18next.changeLanguage('en'); // 기본 언어를 영어로 설정
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Router>
      <SynthwaveBackground />
      <div className="flex flex-col min-h-screen text-white relative z-10">
        <nav className="bg-gray-800 bg-opacity-0 p-4">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center">
              <img 
                src="/nextgame.webp" 
                alt="NextGame Logo" 
                className="w-12 h-12 mr-3"
              />
              <Link to="/" className="text-2xl font-bold">{t('popularGameSeasonTracker')}</Link>
            </div>
            {!USE_STATIC_DATA && (
              <Link to="/admin" className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded">{t('admin')}</Link>
            )}
          </div>
        </nav>
        <main className="flex-grow container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<GameList />} />
            <Route path="/admin" element={<AdminPage />} />
          </Routes>
        </main>
        <footer className="bg-gray-800 bg-opacity-80 text-center py-2">
          <p className="mt-2 text-center text-gray-500 text-sm">&copy; 2024 {t('popularGameSeasonTracker')}. All rights reserved.</p>
          <div className="mt-2">
            <button onClick={() => changeLanguage('en')} className="mx-2 text-blue-400 hover:text-blue-300">English</button>
            <button onClick={() => changeLanguage('ko')} className="mx-2 text-blue-400 hover:text-blue-300">한국어</button>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
